import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Stack } from 'react-bootstrap';
import _ from 'lodash';

import FilterInput from './components/FilterInput';

function App() {
  const [data, setData] = useState({});
  const [brewingMethod, setBrewingMethod] = useState("Autodrip");
  const [showRatio, setShowRatio] = useState(false);
  const [coffeeRatio, setCoffeeRatio] = useState(1);
  const [waterRatio, setWaterRatio] = useState(16);
  const [coffeeUnit, setCoffeeUnit] = useState("Grams");
  const [waterUnit, setWaterUnit] = useState("Grams");
  const [coffeeAmount, setCoffeeAmount] = useState(35);
  const [waterAmount, setWaterAmount] = useState(560);

  const coffeeUnitConversions = {
    "Grams": 1,
    "Tablespoons": .185,
    "Ounces": 0.035,
    "Teaspoons": .555,
    "Number of Beans": 8
  };

  const waterUnitConversions = {
    "Grams": 1,
    "Cups":  0.00563,
    "Fluid Ounces": 0.0338,
    "Milliliters": 1,
    "Liters": .001
  };

  const gramsCoffeeDefault = {
    "Autodrip": 35,
    "Pour Over": 15,
    "Aeropress": 15,
    "French Press": 30,
    "Chemex": 30,
    "Moka Pot": 20,
    "Cold Brew": 30,
    "Siphon": 15,
    "Espresso": 18
  }
  
  useEffect(() => {
    setData({
      "Brewing Method": [
        "Autodrip",
        "Pour Over",
        "Aeropress",
        "French Press",
        "Chemex",
        "Moka Pot",
        "Cold Brew",
        "Siphon",
        "Espresso"
      ],
      "Coffee Unit": [
        "Grams",
        "Tablespoons",
        "Ounces",
        "Teaspoons",
        "Number of Beans"
      ],
      "Water Unit": [
        "Grams",
        "Cups",
        "Fluid Ounces",
        "Milliliters",
        "Liters"
      ]
    });

    resetRatio();
  }, []);

  useEffect(() => {
    setCoffeeAmount(gramsCoffeeDefault[brewingMethod]);
    resetRatio();
  }, [brewingMethod])

  useEffect(() => {
    calculateWaterAmount();
  }, [waterRatio]);

  function round(value, decimals) {
    // return value;
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  }

  const calculateWaterAmount = (newCoffeeAmount) => {
    setWaterAmount(round((newCoffeeAmount || coffeeAmount) / coffeeUnitConversions[coffeeUnit] / coffeeRatio * waterRatio * waterUnitConversions[waterUnit], 2));
  }

  const calculateCoffeeAmount = (newWaterAmount) => {
    setCoffeeAmount(round((newWaterAmount || waterAmount) / waterUnitConversions[waterUnit] / waterRatio * coffeeRatio * coffeeUnitConversions[coffeeUnit], 2));
  }

  /**
   * 
   * @param {string} field 
   * @returns list of options
   */
  const getOptions = (field) => {
    return data[field];
  }

  /**
   * 
   * @param {Array} value selected value
   * @param {string} field 
   * @returns void - returning nothing
   */
  const handleOnChange = (value, field) => {
    value = value.target.value;

    if (field === "Brewing Method") {
      setBrewingMethod(value);
    } else if (field === "Coffee Unit") {
      let amountInGrams = coffeeAmount / coffeeUnitConversions[coffeeUnit];
      setCoffeeAmount(round(amountInGrams * coffeeUnitConversions[value], 2));

      setCoffeeUnit(value);
    } else if (field === "Water Unit") {
      let amountInGrams = waterAmount / waterUnitConversions[waterUnit];
      setWaterAmount(round(amountInGrams * waterUnitConversions[value], 2));
      
      setWaterUnit(value);
    }
  }

  const resetRatio = () => {
    setCoffeeRatio({
      "Autodrip": 1,
      "Pour Over": 1,
      "Aeropress": 1,
      "French Press": 1,
      "Chemex": 1,
      "Moka Pot": 1,
      "Cold Brew": 1,
      "Siphon": 1,
      "Espresso": 1
    }[brewingMethod]);

    setWaterRatio({
      "Autodrip": 16,
      "Pour Over": 15,
      "Aeropress": 16,
      "French Press": 12,
      "Chemex": 17,
      "Moka Pot": 10,
      "Cold Brew": 5,
      "Siphon": 15,
      "Espresso": 2
    }[brewingMethod]);
  }

  return (
    <div className="main-container" >
      <div className="d-flex justify-content-md-center">
        {Object.keys(data).length && <Stack gap={4} className="p-3 square border border-4 p-4">
          <div>
            <h5 className="mb-3">Coffee to Water Ratio</h5>

            <FilterInput
              field="Brewing Method"
              options={getOptions("Brewing Method")}
              handleOnChange={handleOnChange}
              placeholder="Select brewing method..."
              selected={brewingMethod}
            />

            {!showRatio && <div className="mt-3">
              We recommend a <strong>{coffeeRatio}</strong>:<strong>{waterRatio}</strong> ratio of coffee to water for {brewingMethod} but you can <a href="#" onClick={() => {setShowRatio(true)}} className="link-primary" style={{textDecoration: 'none'}}>customize the ratio</a>
            </div>}

            {showRatio && <div>
              <label className="form-label mt-3">
                Ratio&nbsp;
                <a href="#" onClick={() => {resetRatio()}} className="link-primary" style={{textDecoration: 'none'}}>Reset</a>
              </label>
              <div className="row align-items-center text-center">
                <div className="col-auto">
                  <input type="number" min="1" onChange={(e) => {setCoffeeRatio(e.target.value); calculateCoffeeAmount();}} value={coffeeRatio} className="form-control" style={{width: '66px', marginBottom: '10px'}} />
                  <div className="text-secondary">Coffee</div>
                </div>
                <div className="col-auto">
                  to
                </div>
                <div className="col-auto">
                  <input type="number" min="1" onChange={(e) => {setWaterRatio(e.target.value); calculateWaterAmount();}} value={waterRatio} className="form-control" style={{width: '66px', marginBottom: '10px'}} />
                  <div className="text-secondary">Water</div>
                </div>
              </div>
            </div>}
          </div>
          <div>
            <h5 className="mb-3">Units</h5>
            <div class="row">
              <div class="col">
                <FilterInput
                  field="Coffee Unit"
                  options={getOptions("Coffee Unit")}
                  handleOnChange={handleOnChange}
                  placeholder="Select coffee unit..."
                  selected={coffeeUnit}
                />
              </div>
              <div class="col">
                <FilterInput
                  field="Water Unit"
                  options={getOptions("Water Unit")}
                  handleOnChange={handleOnChange}
                  placeholder="Select water unit..."
                  selected={waterUnit}
                />
              </div>
            </div>
          </div>
          <div>
            <h5 className="mb-3">How Much Coffee and Water To Use</h5>

            <div className="row">
              <div className="row align-items-center text-center">
                  <div className="col">
                    <input type="number" min="1" onChange={(e) => {setCoffeeAmount(e.target.value); calculateWaterAmount(e.target.value)}} value={coffeeAmount} className="form-control" style={{marginBottom: '10px'}} />
                    <div>{coffeeUnit} of Coffee</div>
                  </div>
                  <div className="col-auto">
                    and
                  </div>
                  <div className="col">
                    <input type="number" min="1" onChange={(e) => {setWaterAmount(e.target.value); calculateCoffeeAmount(e.target.value)}} value={waterAmount} className="form-control" style={{marginBottom: '10px'}} />
                    <div>{waterUnit} of Water</div>
                  </div>
                </div>
            </div>
          </div>
        </Stack>}
      </div>
    </div>
  );
}

export default App;
