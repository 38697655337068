import React from 'react'
import {Form} from 'react-bootstrap';

const FilterInput = (props) => {
  const {field, options, selected, placeholder, handleOnChange} = props;

  return (
    <Form.Group id={field}>
      <Form.Label>{field}</Form.Label>
      <Form.Select 
        placeholder={placeholder}
        value={selected}
        onChange={(value) => handleOnChange(value, field)}
      >
        <option>{placeholder}</option>
        {options.map((option) => (
          <option value={option} key={option}>{option}</option>
        ))}
      </Form.Select>
    </Form.Group>
  )
}

export default FilterInput